@font-face {
    font-family: "Roboto";
    src: url("fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Bold";
    src: url("fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Light";
    src: url("fonts/Roboto-Light.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

.light-text {
    font-family: "Roboto-Light", sans-serif;
}

.response-container {
    height: 1vh;
    transition: height 0.5s ease-in-out;
}
body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h5 {
    font-family: "Roboto-Bold", sans-serif;
}

.code {
    font-family: "Roboto-Light", source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 17px;
}

.main-bg-color {
    background-color: #014157;
}

.animate__animated.animate__flash {
    --animate-duration: 1s;
    --animate-delay: 2s;
}

li {
    margin: 15px 0;
}